export default {
    data() {
        return {
            expForEachLevel: [10, 12, 15, 20, 27, 40, 59, 88, 125, 168, 227, 298, 381, 482, 591, 728, 885, 1064, 1261, 1488, 1739, 2016, 2327, 2674, 3047, 3456, 3899, 4378, 4899,
                3899, 4378, 4899, 5468, 6075, 4899, 5468, 6075, 6722, 7413, 6075, 6722, 7413, 8156, 8953],
            
            expAccumulatedUpTo45: [129019, 129009, 128997, 128982, 128962, 128935, 128895, 128836, 128748, 128623, 128455, 128228, 127930, 127549, 127067, 126476, 125748, 124863,
                123799, 122538, 121050, 119311, 117295, 114968, 112294, 109247, 105791, 101892, 97514, 92615, 88716, 84338, 79439, 73971, 67896, 62997, 57529, 51454, 44732, 37319, 31244, 24522, 17109, 8953, 0],
            
            expAccumulatedUpTo40: [91700, 91690, 91678, 91663, 91643, 91616, 91576, 91517, 91429, 91304, 91136, 90909, 90611, 90230, 89748, 89157, 88429, 87544, 86480, 85219, 83731,
                 81992, 79976, 77649, 74975, 71928, 68472, 64573, 60195, 55296, 51397, 47019, 42120, 36652, 30577, 25678, 20210, 14135, 7413, 0, 0, 0, 0, 0, 0],
            
            expAccumulatedUpTo35: [61123, 61113, 61101, 61086, 61066, 61039, 60999, 60940, 60852, 60727, 60559, 60332, 60034, 59653, 59171, 58580, 57852, 56967, 55903, 54642, 53154,
                 51415, 49399, 47072, 44398, 41351, 37895, 33996, 29618, 24719, 20820, 16442, 11543, 6075, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
    }
};